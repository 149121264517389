import * as React from 'react';
import { boolean, object, ref, string } from 'yup';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { postUsers } from 'api/userAuthentication';
import { Button, Link } from 'styleguide/components';
import FormWrapper from '../FormWrapper/FormWrapper';
import { H4 } from 'styleguide/components/Heading';
import Grid from 'styleguide/components/Grid/Grid';
import { Status } from 'libs/utils/api/types';
import { signInPath, privacyPath, termsAndConditionsPath } from 'bundles/App/routes';
import Meta from 'styleguide/components/Meta/Meta';
import UserContext from 'contexts/UserContextContainer/UserContext';
import Loader from 'styleguide/components/Loader/Loader';
import TwoColumnFormField from 'styleguide/components/forms/Field/TwoColumnFormField';
import Label from 'styleguide/components/Formik/Label/Label';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import FormikFieldWrapper from 'app/styleguide/components/Formik/FormikFieldWrapper/FormikFieldWrapper';
import { signUpSuccess } from 'api/gtm';
import { useLocation } from 'react-router-dom';
import { isProductPage } from 'libs/utils/pageUtils';
import AppContext from 'contexts/AppContext/AppContext';

interface LocationState {
  from: string;
}

const SignUpFormSchema = object().shape({
  email: string()
    .strict()
    .email('Please enter a valid email.')
    .trim('Email cannot begin or end with spaces.')
    .required('Email is required.'),
  firstName: string()
    .strict()
    .trim('Name cannot begin or end with spaces.')
    .required('First name is required.'),
  lastName: string()
    .strict()
    .trim('Name cannot begin or end with spaces.')
    .required('Last name is required.'),
  password: string()
    .ensure()
    .test('password-test', 'Password must be at least 8 characters.', value =>
      value === '' ? true : value.length >= 8,
    ),
  confirmation: string()
    .ensure()
    .when('password', {
      is: value => value === '',
      then: schema => schema.oneOf([''], 'Your password and confirmation do not match.'),
      otherwise: schema => schema.oneOf([ref('password')], 'Your password and confirmation do not match.'),
    }),
  subscribeMail: boolean(),
});

const SignUpPage = () => {
  const userContext = React.useContext(UserContext);
  const appContext = React.useContext(AppContext);
  const location = useLocation<LocationState>();

  const signUp = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    postUsers(values).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        userContext.logIn(res.payload.user);
        if (location.state?.from && isProductPage(location.state?.from, appContext.store.cmsPages)) {
          signUpSuccess({ logged_in: true, email: values.email });
        }
      }
    });
  };

  return (
    <>
      <Meta
        title="SignUp"
        description=""
        keywords=""
        canonical="https://www.printivity.com/sign-up"
        robots={['noindex']}
      />
      <FormWrapper signUp>
        <H4
          data-cy="signUpTitle"
          className="'text-left -sm:mt-4' !font-hvBold !text-4xl !font-bold -md:mt-10"
        >
          Sign Up
        </H4>
        <div data-cy="signUpWelcomeTitle" className="paragraph-desktop mb-9 mt-4 text-gray-500">
          Glad to see you here! Please enter your details.
        </div>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmation: '',
            subscribeMail: true,
          }}
          onSubmit={(values, { setErrors, setSubmitting, setStatus }) => {
            signUp(values, setStatus, setSubmitting, setErrors);
          }}
          validationSchema={SignUpFormSchema}
        >
          {formikProps => (
            <Form>
              <TwoColumnFormField leftFieldClassName="!pl-0 md:!pr-4" rightFieldClassName="!px-0" heading="">
                <FormikFieldWrapper
                  inPlaceError
                  name="firstName"
                  componentType="input"
                  labelComponent="First Name"
                  inputClassName="mb-1"
                  data-cy="firstName"
                />
                <FormikFieldWrapper
                  inPlaceError
                  name="lastName"
                  componentType="input"
                  labelComponent="Last Name"
                  inputClassName="mb-1"
                  data-cy="lastName"
                />
              </TwoColumnFormField>
              <FormikFieldWrapper
                inPlaceError
                type="email"
                name="email"
                componentType="input"
                labelComponent="Email"
                inputClassName="mb-1"
                data-cy="email"
              />
              <FormikFieldWrapper
                inPlaceError
                type="password"
                name="password"
                componentType="input"
                labelComponent="Password"
                inputClassName="mb-1"
                data-cy="password"
              />
              <FormikFieldWrapper
                inPlaceError
                type="password"
                name="confirmation"
                componentType="input"
                labelComponent="Confirm Password"
                data-cy="confirmPassword"
              />
              <div className="flex">
                <FormikFieldWrapper
                  name="subscribeMail"
                  componentType="checkbox"
                  size="sm"
                  data-cy="sendEmailTips"
                  labelComponent={
                    <Label placement="left" normalFontWeight asSpan>
                      Sure! Occasionally send me an email about Printivity tips and deals.
                    </Label>
                  }
                />
              </div>
              <Grid.Row>
                {formikProps.isSubmitting ? (
                  <Loader />
                ) : (
                  <Button
                    className="mt-9 w-[360px] !px-0 -sm:w-[320px]"
                    type="button"
                    onClick={() => formikProps.submitForm()}
                    color="blue"
                    data-cy="createAccountButton"
                  >
                    Create An Account
                  </Button>
                )}
              </Grid.Row>
            </Form>
          )}
        </Formik>
        <div className="text-center">
          <div className="caption my-4 text-gray-500">or</div>
          <Button.AsNativeLink
            className="w-full !px-0"
            buttonType="link"
            color="dark"
            outline
            target={signInPath}
            size="sm"
            dataCy="signInButton"
          >
            Sign In
          </Button.AsNativeLink>
        </div>
        <div className="caption mt-9 border-t border-solid border-gray-50 pt-9 text-gray-500 -sm:mb-4">
          By creating an account, you are agreeing to our{' '}
          <Link
            to={termsAndConditionsPath}
            color="blue"
            underline="none"
            className="!font-hvRoman !text-xs !font-normal !leading-4"
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            to={privacyPath}
            color="blue"
            underline="none"
            className="!font-hvRoman !text-xs !font-normal !leading-4"
          >
            Privacy Policy
          </Link>
          .
        </div>
      </FormWrapper>
    </>
  );
};
export default SignUpPage;
